/**
 * Slider Component
 *
 * @file slider.js
 */

import $ from "jquery";



const careerForm = (() => {
  /**
   * fullSlider
   * Full width slider settings
   */
  const careerForm = () => {
    $('.custom-file-input').change(function(e){
        var fileName = e.target.files[0].name;
        $(".custom-file-name").html(fileName)
    });
  };
  const init = () => {
    careerForm();
  };

  return {
    init,
  };
})();

export default careerForm;

/**
 * Lightbox Component
 *
 * @file lightbox.js
 */
 import $ from "jquery";
 require('fancybox')($);
 import 'fancybox/dist/css/jquery.fancybox.css';

 const lightbox = (() => {
    /**
     * Default lightbox
     * Project default lightbox style
     */
    const defaultLightbox = () => {
        $(document).ready(function() {
            $('.fancybox').fancybox();
        });
    };
  
    const init = () => {
      defaultLightbox();
    };
  
    return {
      init,
    };
  })();
  
  export default lightbox;
  
/**
 * Tab Component
 *
 * @file tab.js
 */

const tabs = (() => {
  /**
   * Default Tab
   * Project default tab style
   */
  const defaultTab = () => {
    const listLi = document.getElementsByClassName("select-filter-li");
    for (let i = 0; i < listLi.length; i += 1) {
      listLi[i].addEventListener('click', function (event) {
        let eventText = event.currentTarget.innerHTML;
        document.getElementById("dd").innerHTML = eventText;
        for (let k = 0; k < listLi.length; k++) {
          listLi[k].classList.remove('active');
        }
        event.currentTarget.classList.toggle('active');
      });
    }
  };

  const init = () => {
    defaultTab();
  };

  return {
    init,
  };
})();

export default tabs;

/**
 * Get Current Browser
 *
 * @file browser.js
 */
const browser = (() => {
  const nAgt = navigator.userAgent;
  let browserName = navigator.appName;
  let fullVersion = `${ parseFloat(navigator.appVersion) }`;
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset;
  let verOffset;
  let ix;

  /**
   * Get Browser Detail
   * Browser name and version
   */
  const getBrowserDetail = () => {
    if ((nAgt.indexOf('Opera')) !== -1) {
      verOffset = nAgt.indexOf('Opera');
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      if ((nAgt.indexOf('Version')) !== -1) {
        verOffset = nAgt.indexOf('Version');
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((nAgt.indexOf('MSIE')) !== -1) {
      verOffset = nAgt.indexOf('MSIE');
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((nAgt.indexOf('Chrome')) !== -1) {
      verOffset = nAgt.indexOf('Chrome');
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    } else if ((nAgt.indexOf('Safari')) !== -1) {
      verOffset = nAgt.indexOf('Safari');
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);
      if ((nAgt.indexOf('Version')) !== -1) {
        verOffset = nAgt.indexOf('Version');
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((nAgt.indexOf('Firefox')) !== -1) {
      verOffset = nAgt.indexOf('Firefox');
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    } else if ((nAgt.lastIndexOf(' ') + 1) < (nAgt.lastIndexOf('/'))) {
      nameOffset = nAgt.lastIndexOf(' ') + 1;
      verOffset = nAgt.lastIndexOf('/');
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);

      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    // trim the fullVersion string at semicolon/space if present
    if ((fullVersion.indexOf(';')) !== -1) {
      ix = fullVersion.indexOf(';');
      fullVersion = fullVersion.substring(0, ix);
    }

    if ((fullVersion.indexOf(' ')) !== -1) {
      ix = fullVersion.indexOf(' ');
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt(`${ fullVersion }`, 10);

    // eslint-disable-next-line
    if (isNaN(majorVersion)) {
      fullVersion = `${ parseFloat(navigator.appVersion) }`;
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    return { name: browserName, version: majorVersion };
  };

  return {
    getDetail: getBrowserDetail,
  };
})(navigator, document);

export default browser;

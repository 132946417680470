const accordion = (() => {
  const defaultAccordion = () => {
    const accordionItem = document.getElementsByClassName('js-trigger-accordion');

    for (let i = 0; i < accordionItem.length; i += 1) {
      accordionItem[i].addEventListener('click', function (event) {
        event.currentTarget.classList.toggle('js-active');
        const accordionContent = this.nextElementSibling;
        event.currentTarget.querySelector('.c-accordion__icon svg').classList.toggle('js-active');
        accordionContent.classList.toggle('js-active');
      });
    }
  };


    const bilgiToplumHizmetleriAccordion = () => {
      const bilgiToplumHizmetleriAccordion = document.getElementsByClassName('js-accordion-tabs');
  
      for (let i = 0; i < bilgiToplumHizmetleriAccordion.length; i += 1) {
        bilgiToplumHizmetleriAccordion[i].addEventListener('click', function (event) {
          event.currentTarget.classList.toggle('js-active');
          const accordionContent = this.nextElementSibling;
          event.currentTarget.querySelector('.c-accordion__icon svg').classList.toggle('js-active');
          accordionContent.classList.toggle('js-active');
        });
      }
    };


    const subpageAccordion = () => {
      const accordionItem2 = document.getElementsByClassName('tab-link-a');
  
      for (let i = 0; i < accordionItem2.length; i += 1) {
        accordionItem2[i].addEventListener('click', function (event) {
          const accTp = event.currentTarget.parentNode;
          for (let i = 0; i < accTp.children.length; i++) {
            accTp.children[i].classList.remove('active');
          }
          event.currentTarget.classList.toggle('active');
          /*multiple*/
          const accHref = this.getAttribute("data-id");
          const accId = document.getElementById(accHref).parentNode;
          for (let i = 0; i < accId.children.length; i++) {
            accId.children[i].classList.remove('active');
          }
  
          document.getElementById(accHref).classList.add('active');
  
        });
      }
    };


    const whoWheAreAccordion = () => {
      const whoWeAccordionItem = document.getElementsByClassName('who-whe-are-tab-link');
  
      for (let i = 0; i < whoWeAccordionItem.length; i += 1) {
        whoWeAccordionItem[i].addEventListener('click', function (event) {
          const accTp = event.currentTarget.parentNode;
          for (let i = 0; i < accTp.children.length; i++) {
            accTp.children[i].classList.remove('active');
          }
          event.currentTarget.classList.toggle('active');
          /*multiple*/
          const accHref = this.getAttribute("data-id");
          const accId = document.getElementById(accHref).parentNode;
          for (let i = 0; i < accId.children.length; i++) {
            accId.children[i].classList.remove('active');
          }
  
          document.getElementById(accHref).classList.add('active');
  
        });
      }
    };


    const applicationAccordion = () => {
      const whoWeAccordionItem = document.getElementsByClassName('who-whe-are-tab-link');
  
      for (let i = 0; i < whoWeAccordionItem.length; i += 1) {
        whoWeAccordionItem[i].addEventListener('click', function (event) {
          const accTp = event.currentTarget.parentNode;
          for (let i = 0; i < accTp.children.length; i++) {
            accTp.children[i].classList.remove('active');
          }
          event.currentTarget.classList.toggle('active');
          /*multiple*/
          const accHref = this.getAttribute("data-id");
          const accId = document.getElementById(accHref).parentNode;
          for (let i = 0; i < accId.children.length; i++) {
            accId.children[i].classList.remove('active');
          }
  
          document.getElementById(accHref).classList.add('active');
  
        });
      }
    };

  const init = () => {
    defaultAccordion();
    subpageAccordion();
    bilgiToplumHizmetleriAccordion();
    whoWheAreAccordion();
    applicationAccordion();
  };

  return {
    init,
  };
})();

export default accordion;

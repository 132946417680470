/**
 * breadcrumb Component
 *
 * @file breadcrumb.js
 */
 import $ from "jquery";
 require('fancybox')($);
 import 'fancybox/dist/css/jquery.fancybox.css';

 const breadcrumb = (() => {
    /**
     * Default breadcrumb
     * Project default breadcrumb style
     */
    const defaultBreadcrumb = () => {
        $(document).ready(function() {
            $(".breadcrumb").hover(function() {
                $(this).find(".br-bg").stop().css("height", ($(".menu-list").innerHeight() + 50) + "px")
                $(this).find(".menu-list").stop().slideDown();
            }, function() {
                $(this).find(".menu-list").stop().slideUp();
                $(this).find(".br-bg").stop().css("height", "50px");
            });
        });
    };
  
    const init = () => {
      defaultBreadcrumb();
    };
  
    return {
      init,
    };
  })();
  
  export default breadcrumb;
  